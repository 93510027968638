<template>
  <v-menu v-if="cities?.length" v-model="isMenuOpen">
    <template #activator="{ props }">
      <div class="bg-bg_color hover:bg-nav_color hover:bg-opacity-10 p-2 mb-2 rounded">
        <div
          v-bind="props"
          class="cursor-pointer flex justify-between"
        >
          <div class="flex">
            <v-icon icon="mdi mdi-map-marker" :size="24" />
            <div :class="{ 'text-text_color text-opacity-60': !selectedCity?.name }">
              {{ selectedCity?.name || t('select_city') }}
            </div>
          </div>
          <v-icon icon="mdi mdi-menu-down" :size="24" />
        </div>
      </div>
    </template>
    <v-list class="dropdown-menu">
      <div
        v-for="(city, index) in cities"
        :key="`locale-item ${index}`"
        class="my-1 mx-3  text-center cursor-pointer"
        @click="selectCity(city)"
      >
        <div
          :class="{ 'bg-bg_color': city.id.toString() == modelValue?.toString() }"
        >
          {{ city.name }}
        </div>
      </div>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { cities } = useCities()
defineProps<{
  modelValue?: number
}>()
const selectedCity = ref<City | null>(null)
const isMenuOpen = ref<boolean>(false)
function selectCity(city:City) {
  selectedCity.value = city
  emit('update:modelValue', city.id)
  isMenuOpen.value = false
}
const emit = defineEmits<{
  (event: 'update:modelValue', value?: number): void
}>()
</script>
<style scoped>
.dropdown-menu {
  @apply overflow-y-auto bg-bg_color mt-2 text-text_color  max-h-48;
}
</style>
