<template>
  <div>
    <div
      v-if="permission == 'denied'"
      class="p-1 mb-1 text-sm text-center text-red-500 bg-red-100 border border-red-500 rounded"
    >
      {{ t('location_permission_denied') }}
    </div>
    <div class="flex justify-between mb-2">
      <!-- <GMapAutocomplete
        class="flex-grow w-full p-2 bg-text_color text-text_color bg-opacity-5 border-2 rounded outline-none text-center"
        :placeholder="t('enter_place')"
        @place_changed="goToPlace"
      >
      </GMapAutocomplete> -->
      <button
        v-if="permission == 'granted' || permission == 'prompt'"
        :disabled="loading"
        class="px-4 py-1 text-text_alt_color rounded bg-nav_color"
        @click="getCurrentPosition"
      >
        <div
          v-if="loading"
          class="w-5 h-5 border-t-4 border-r-4 rounded-full animate-spin border-t-white border-r-transparent"
        ></div>
        <v-icon icon="mdi mdi-map-marker" size="20" />
      </button>
    </div>

    <GoogleMap
      ref="placeMap"
      :api-key="useMapKey()"
      :center="center"
      :zoom="12"
      :libraries="['places']"
      :street-view-control="false"
      :map-type-control="false"
      :fullscreen-control="false"
      :clickable-icons="false"
      style="width: 100%; height: 200px; margin: auto"
      @zoom_changed="updateCenterFromMap"
      @dragend="updateCenterFromMap"
      @click="updateCenter"
    >
      <Marker
        :options="{ position: center, draggable: true }"
        @dragend="updateCenterFromMap"
      />
    </GoogleMap>
  </div>
</template>

<script setup lang="ts">
import { GoogleMap, Marker } from 'vue3-google-map'
import debounce from 'lodash/debounce'
import type { LocationModel } from '~~/composables/useMenuModel'

const props = defineProps<{ location?: LocationModel }>()
const center = ref<LocationModel>(
  props.location ?? { lat: 36.58709815183801, lng: 37.046037335040864 }
)
const placeMap = ref<typeof GoogleMap | null>(null)
const permission = ref<string>('')
const loading = ref<boolean>(false)
const { t } = useI18n()

const emit = defineEmits(['ids-location', 'geo-location'])

onMounted(() => {
  if (!props.location) {
    getCurrentPosition()
  }

  navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
    permission.value = result?.state
    if (result?.state === 'prompt' || result.state === 'granted') {
      if (!location) {
        getCurrentPosition()
      }
    }
    result.onchange = () => {
      permission.value = result?.state
    }
  })
})

function goToPlace(data: any) {
  setPosition(data.geometry.location.lat(), data.geometry.location.lng(), true)
}

function updateCenter({ latLng }) {
  if (latLng != null) {
    setPosition(latLng.lat(), latLng.lng(), true)
  } else {
    updateCenterFromMap(true)
  }
}

function updateCenterFromMap(notForceUpdate?: boolean) {
  if (!placeMap.value) {
    return
  }

  setPosition(
    placeMap.value.map.center.lat(),
    placeMap.value.map.center.lng(),
    false,
    notForceUpdate
  )
}

const setPosition = debounce(
  async (
    lat: string,
    lng: string,
    withSmoothzoom: boolean,
    notForceUpdate?: boolean
  ) => {
    if (
      center.value.lat.toString() === lat &&
      center.value.lng.toString() === lng &&
      !notForceUpdate
    ) {
    } else {
      center.value = {
        lat: Number(lat),
        lng: Number(lng)
      }

      emit('geo-location', center.value)

      if (withSmoothzoom) {
        const map = placeMap.value?.map

        await map.panTo(center.value)
        smoothZoom(map, 17, map.getZoom())
      }
    }
  },
  1000
)

const getCurrentPosition = () => {
  // Check if geolocation is supported by the browser
  const isSupported = 'navigator' in window && 'geolocation' in navigator
  if (isSupported) {
    loading.value = true
    // Retrieve the user's current position
    navigator.geolocation.getCurrentPosition(
      (position) => {
        center.value.lat = position.coords.latitude
        center.value.lng = position.coords.longitude
        loading.value = false
      },
      () => {
        loading.value = false
      }
    )
  }
}

function smoothZoom(map, max, cnt) {
  if (cnt >= max) {
    return
  }
  const z = google.maps.event.addListener(
    map,
    'zoom_changed',
    function (event) {
      google.maps.event.removeListener(z)
      smoothZoom(map, max, cnt + 1)
    }
  )
  setTimeout(function () {
    map.setZoom(cnt)
  }, 80) // 80ms is what I found to work well on my system -- it might not work well on all systems
}
</script>
