<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/3 p-2">
        <MyAccountPlacesTextareaField
          v-model="form.text_address"
          rows="1"
          class="h-[53px]"
          :placeholder="t('name_location')"
          error="agent_place-text_address"
        />
        <MyAccountPlacesCitiesDropDown v-model="form.city_id" />
        <MyAccountPlacesTextareaField
          v-model="form.details"
          rows="6"
          class="h-[200px] mb-3"
          :placeholder="t('details')"
          error="agent_place-details"
        />
      </div>
      <div class="flex-auto p-2 w-full sm:w-2/3">
        <client-only>
          <MyAccountPlacesGoogleMap
            :enable-search="true"
            class="mx-auto w-full"
            @geo-location="form.json_address = JSON.stringify($event)"
          />
        </client-only>
      </div>
    </div>

    <div class="wrapper">
      <div class="field">
        <AuthTextField
          v-model="form.street"
          :label="t('street')"
          type="text"
          input-class="text-center text-sm"
          error="agent_place-street"
        />
      </div>

      <div class="field">
        <AuthTextField
          v-model="form.building"
          :label="t('building')"
          type="text"
          input-class="text-center text-sm"
          error="agent_place-building"
        />
      </div>
    </div>
    <div class="wrapper">
      <div class="field">
        <AuthTextField
          v-model="form.floor"
          :label="t('floor')"
          type="text"
          input-class="text-center text-sm"
          error="agent_place-floor"
        />
      </div>

      <div class="field">
        <AuthTextField
          v-model="form.apartment"
          :label="t('apartment')"
          type="text"
          input-class="text-center text-sm"
          error="agent_place-apartment"
        />
      </div>
    </div>
    <!-- Submit -->
    <SelaResponseError
      class="my-2"
      api-key="agent_place"
      :except="[
        'agent_place-details',
        'agent_place-json_address',
        'agent_place-street',
        'agent_place-building',
        'agent_place-floor',
        'agent_place-postal_code',
        'agent_place-apartment'
      ]"
    />
    <div class="w-40 mx-auto">
      <SelaSubmitButton
        class="my-2"
        :title="t('create_address')"
        :loading="loadingCreatePlace"
        @submit="createAddress"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { form, loadingCreatePlace, createPlace } = useCreatePlace()
const { AgentPlacesValue } = useAgentPlaces()
const { t } = useI18n()

const emit = defineEmits(['created'])

async function createAddress() {
  const newAddress = await createPlace()
  if (newAddress) {
    form.details = ''
    form.street = ''
    form.building = ''
    form.city_id = undefined
    form.text_address = ''
    form.floor = ''
    form.apartment = ''
    form.postal_code = ''
    AgentPlacesValue.value.places.push(newAddress)
    emit('created', newAddress)
  }
}
</script>

<style scoped>
.wrapper {
  @apply flex flex-wrap;
}

.field {
  @apply px-1 flex-1 min-w-[15rem];
}
</style>
