import { useToast } from 'vue-toastification'
import { useApiErrorsStore, fetchToApiError } from '~~/store/api_errors'

export const useCreatePlace = () => {
  const toast = useToast()
  const { t } = useI18n()

  const form = reactive({
    details: '',
    json_address: JSON.stringify({
      lat: 36.58709815183801,
      lng: 37.046037335040864
    }),
    street: '',
    building: '',
    city_id: undefined,
    text_address: '',
    floor: '',
    apartment: '',
    postal_code: '',
    sourceType: 'App\\Models\\Agent'
  })

  const { addApiError, deleteApiError } = useApiErrorsStore()
  const loadingCreatePlace = ref<boolean>(false)
  async function createPlace(): Promise<any> {
    const { status } = useAuth()

    if (status.value !== 'authenticated') {
      toast.error(t('login_required'), { timeout: 1000 })
      return false
    }
    if (!form.text_address.trim()) {
      toast.error(t('required', {
        name: t('name_location')
      }), { timeout: 1000 })
      return false
    }
    if (!form.details.trim()) {
      toast.error(t('required', {
        name: t('details')
      }), { timeout: 1000 })
      return false
    }
    if (!form.city_id) {
      toast.error(t('required', {
        name: t('city')
      }), { timeout: 1000 })
      return false
    }
    try {
      deleteApiError('agent_place')
      loadingCreatePlace.value = true
      const data = await $fetch('/nuxt-api/user/agentPlace/createAgentPlace', {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value,
          ...form
        }
      })

      toast.success(t('create_done'), { timeout: 1000 })
      loadingCreatePlace.value = false

      return data.data
    } catch (error) {
      toast.error(t('create_failed'), { timeout: 1000 })
      loadingCreatePlace.value = false
      addApiError(fetchToApiError(error), 'agent_place')
      return false
    }
  }

  return {
    form,
    loadingCreatePlace,
    createPlace
  }
}

export const useDeletePlace = () => {
  const toast = useToast()
  const { t } = useI18n()
  const { addApiError, deleteApiError } = useApiErrorsStore()
  async function deletePlace(placeId: number): Promise<boolean> {
    const { status } = useAuth()

    if (status.value !== 'authenticated') {
      toast.error(t('login_required'), { timeout: 1000 })
      return false
    }

    try {
      deleteApiError(`deleteAgentPlace-${placeId}`)
      await $fetch('/nuxt-api/user/agentPlace/deleteAgentPlace', {
        method: 'DELETE',
        body: {
          place_id: placeId
        }
      })
      return true
    } catch (error) {
      toast.error(t('delete_failed'), { timeout: 1000 })
      addApiError(fetchToApiError(error), `deleteAgentPlace-${placeId}`)
      return false
    }
  }

  return {
    deletePlace
  }
}
